.Experience-container {
  height: 130px;
  margin: auto;
  width: 1000px;
}

.Experience-title {
  height: 1em;
  margin-top: 30px;
  margin-left: 85px;
  font-size: 4rem;
  font-weight: 400;
  color: #3D3D3D;
}

.Experience-subtitle {
  height: 1em;
  margin-top: 20px;
  margin-left: 89px;
  font-size: 1rem;
  font-weight: 400;
  color: #3D3D3D;
}

.resume-link {
  text-decoration: underline;
  color: #3D3D3D;
}

.experience-section {
  color: #3D3D3D;
  width: 700px;
  margin: auto;
  padding: none;
  padding-top: 30px;
}

.experience-section-row {
  align-items: center;
  width: 700px;
  margin: auto;
  display: flex;
  padding-bottom: 70px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
}

.col {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 100%;
  background-color: transparent;
}

.experience-section-text-wrapper-styleone {
  max-width: 500px;
  text-align: left;
  margin-left: none;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  align-items: left;
}

.experience-section-text-wrapper-styletwo {
  max-width: 500px;
  text-align: right;
  margin-right: none;
  padding-right: none;
}

.heading {
  margin-bottom: 10px;
  font-size: 1.9rem;
  line-height: 1.3;
  font-weight: 500;
  color: #3D3D3D;
}

.experience-section-img-wrapper-styleone {
  margin: 0;
}

.experience-section-img {
  max-width: 95%;
  margin-top: 0;
  margin-right: 0;
  margin-left: 10px;
  padding-right: 0;
}

img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

.ardralogo {
  width: 300px;
}

.apotexlogo {
  width: 250px;
}

.hoarelablogo {
  width: 150px;
}

.suncorlogo {
  width: 250px;
}

.leaplogo {
  width: 150px;
}

.Chembiologo {
  width: 180px;
}

.AFlogo {
  width: 150px;
}

.experience-down-arrow {
  margin-right: auto;
  margin-left: auto;
  margin-bottom: auto;
  margin-top: 20px;
  width: 20px;
  font-size: 2rem;
  color: #70707046;
}

@media screen and (max-width: 991px) {
  .Experience-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media screen and (max-width: 768px) {
  .experience-section-text-wrapper {
    padding-bottom: 65pm;
  }

  .col {
    max-width: 100%;
    flex-basis: 100%;
  }
}


