.Hero-Intro {
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 40px;
  padding: 10px;
  width: 700px;
  height: 300px;
}

.Hero-title {
  height: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5rem;
  font-weight: 600;
  color: #3D3D3D;
}

.Hero-subtitle {
  height: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5rem;
  font-weight: 400;
  color: #3D3D3D;
}

.description {
  /* margin-left: 29vw;
  margin-right: 29vw; */
  height: 1em;
  width: 37em;
  margin: auto;
}

.Hero-description {
  height: 16vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.3rem;
  font-weight:400;
  color: #3D3D3D;
}
/* with status bar */
/* .Hero-meters { 
  margin-top: 20px;
  margin-left: 60px;
  margin-right: 60px;
  margin-bottom: 70px;
} */

/* alone in the middle */
.Hero-meters { 
    margin-top: 20px;
    margin-left: 60px;
    margin-right: 60px;
    margin-bottom: 70px;
  }

.Hero-education { 
    margin: auto
  }  

.Hero-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  justify-content: center;
  align-items: center;
  width: 990px;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  margin: 2rem;
}

.education-meter {
  width: 29rem;
  margin-inline-end: auto;
}

.status-meter {
  width: 29rem;
}

.Hero-status {
  margin-top: 50px;
}

.status-column {
  margin-top: -20px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 98px;
}

.meter-title {
  height: 4.6vh;
  font-size: 1.7rem;
  font-weight: 500;
  color: #3D3D3D;
}

.meter-subtitle {
  height: 3.5vh;
  font-size: 1.2rem;
  font-weight: 400;
  color: #3D3D3D;
}

.meter-learnmore {
  height: 1vh;
  font-size: 0.8rem;
  font-weight: 400;
  color: #3D3D3D;
}

.Hero-down-arrow {
  margin-left: auto;
  margin-right: auto;
  margin-top: 70px;
  margin-bottom: 70px;
  width: 20px;
  font-size: 2rem;
  color: #70707046;
}

@media screen and (max-width: 991px) {

  .Hero-meters {
    margin-top: 0;
  }
  
  .Hero-row {
    width: 750px;
  }

  .meter-title {
    font-size: 1.3rem;
    margin-top: 5px;
  }

  .meter-subtitle {
    font-size: 1rem;
    margin-top: 0;
    line-height: 0.9;
  }

  .meter-learnmore {
    font-size: 0.7rem;
  }

  .status-column {
    margin-left: 70px;
  }

  .Hero-down-arrow {
    margin-top: 70px;
  }
}

@media screen and (max-width: 768px) {
  .Hero-Intro {
    width: 600px;
  }

  .Hero-title {
    font-size: 4.5rem;
  }

  .Hero-subtitle {
    font-size: 4.5rem;
  }
  
  .Hero-description {
    width: 550px;
    font-size: 1.2rem;
  }

  .Hero-meters {
    margin: 0;
  }
  
  .Hero-row {
    display: block;
    align-items: center;
    margin: auto;
    width: 600px;
  }

  .Hero-education {
    margin: auto;
    padding: 0;
    width: 400px;
  }

  .Hero-status {
    margin: auto;
    padding-top: 50px;
    width: 400px;
  }

  .meter-title {
    font-size: 1.3rem;
    margin-top: 5px;
  }

  .meter-subtitle {
    font-size: 1rem;
    margin-top: 0;
    line-height: 0.9;
  }

  .meter-learnmore {
    font-size: 0.7rem;
  }

  .status-column {
    margin-left: 70px;
  }

  .Hero-down-arrow {
    margin-top: 70px;
  }
}

@media screen and (max-width: 600px) {
  .Hero-Intro {
    width: 400px;
    height: 230px;
  }

  .Hero-title {
    font-size: 3.5rem;
  }

  .Hero-subtitle {
    font-size: 3.5rem;
  }
  
  .Hero-description {
    width: 400px;
    font-size: 0.88rem;
  }
  
  .Hero-meters {
    margin: 0;
  }
  
  .Hero-row {
    display: block;
    align-items: center;
    margin: auto;
    width: 400px;
  }

  .Hero-education {
    margin: auto;
    padding: 0;
    width: 300px;
  }

  .Hero-status {
    margin: auto;
    padding-top: 0px;
    width: 300px;
  }

  .meter-title {
    font-size: 1.3rem;
    margin-top: 5px;
  }

  .meter-subtitle {
    font-size: 1rem;
    margin-top: 0;
    line-height: 0.9;
  }

  .meter-learnmore {
    font-size: 0.7rem;
  }

  .status-column {
    margin-left: 70px;
  }

  .Hero-down-arrow {
    margin-top: 70px;
  }
}