* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  background-color: #EFF9EF;
}

.backtotop {
  font-size: 16px;
  color: #3d3d3dcb;
  background-color: #F6FFF6;
  border-radius: 20px;
  padding: 13px;
  box-shadow: 3px 3px 18px 2px #a5ada542;
}

