.contact-box {
  height: 300px;
  margin: auto;
  width: 940px;
}

.contact-title {
  height: 1em;
  margin-top: 40px;
  margin-bottom: 25px;
  font-size: 4rem;
  font-weight: 400;
  color: #3D3D3D;
}

.contact-link {
  text-decoration: underline;
  color: #3D3D3D;
}

.footer-link {
  text-decoration: none;
  color: #3D3D3D;
  float: right;
  margin-right: 50px;
  margin-bottom: 50px;
}